/* eslint-disable no-undef */
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Help from '@material-ui/icons/Help';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Badge } from '@material-ui/core';

import * as adminActions from '../../actions/adminMenuActions';
import * as helpActions from '../../actions/helpMenuActions';
import * as userActions from '../../actions/userMenuActions';
import * as whatsNewActions from '../../actions/whatsNewActions';
import AdminMenu from './AdminMenu';
import HelpMenu from './HelpMenu';
import UserMenu from './UserMenu';
import WhatsNewMenu from './WhatsNewMenu';


const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class TopNav extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      anchorElAdmin: false,
      anchorElHelp: false,
      anchorElUser: false,
      anchorElWhatsNew: false,
      adminMenuData: Object.assign([], props.adminMenuData),
      helpMenuData: Object.assign([], props.helpMenuData),
      userMenuData: Object.assign([], props.userMenuData),
      whatsNewMenuData: Object.assign([], props.whatsNewMenuData),
      fullName : 'User'
    };

    this.handleAdminMenu = this.handleAdminMenu.bind(this);
    this.handleHelpMenu = this.handleHelpMenu.bind(this);
    this.handleUserMenu = this.handleUserMenu.bind(this);
    this.handleWhatsNewMenu = this.handleWhatsNewMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount(){

    const that = this;
    // Admin
    global.uniNavAdmin = {
      create(data) {
        that.props.adminActions.create(data);
        that.setState({
          adminMenuData : Object.assign([], data)
        });
      },

      read() {
        return that.state.adminMenuData;
      },

      update(data) {
        that.props.adminActions.update(data);
      },

      delete(data) {
        that.props.adminActions.remove(data);
        that.setState(that.state);
      }
    };

    // Help
    global.uniNavHelp = {
      create(data) {
        that.props.helpActions.create(data);
        that.setState({
          helpMenuData : Object.assign([], data)
        });
      },

      read() {
        return that.state.helpMenuData;
      },

      update(data) {
        that.props.helpActions.update(data);
      },

      delete(data) {
        that.props.helpActions.remove(data);
        that.setState(that.state);
      }
    }; 
    
    // Whats New
    global.uniNavWhatsNew = {
      create(data) {
        that.props.whatsNewActions.create(data);
        that.setState({
            whatsNewMenuData : Object.assign([], data)
        });
      },

      read() {
        return that.state.whatsNewMenuData;
      },

      update(data) {
        that.props.whatsNewActions.update(data);
      },

      delete(data) {
        that.props.whatsNewActions.remove(data);
        that.setState(that.state);
      }
    };

    // User
    global.uniNavUser = {
      setUser(fullName) {
        that.setState({ fullName : fullName});
      },
      create(data) {
        that.props.userActions.create(data);
        that.setState({
          userMenuData : Object.assign([], data)
        });
      },

      read() {
        return that.state.userMenuData;
      },

      update(data) {
        that.props.userActions.update(data);
      },

      delete(data) {
        that.props.userActions.remove(data);
        that.setState(that.state);
      }
    };
  }

  handleWhatsNewMenu(event) {
      this.setState({ anchorElWhatsNew: event.currentTarget })
  }

  handleAdminMenu(event) {
    this.setState({ anchorElAdmin: event.currentTarget });
  }

  handleHelpMenu(event) {
    this.setState({ anchorElHelp: event.currentTarget });
  }

  handleUserMenu(event) {
    this.setState({ anchorElUser: event.currentTarget });
  }

  handleClose() {
    this.setState({ 
        anchorElAdmin: null, 
        anchorElHelp: null, 
        anchorElUser: null,  
        anchorElWhatsNew: null
    });
  }

  render() {
    const { classes } = this.props;
    const { anchorElAdmin, anchorElHelp, anchorElUser, anchorElWhatsNew, fullName } = this.state;
    const openAdminMenu = Boolean(anchorElAdmin);
    const openHelpMenu = Boolean(anchorElHelp);
    const openUserMenu = Boolean(anchorElUser);
    const openWhatsNewMenu = Boolean(anchorElWhatsNew);

    return (
      <div id="uniNav-top-nav" className={classes.root}>
          <Toolbar className="toolbar">
            <div id="unifyPlaceholder1" className={classes.grow}></div>
            <div id="unifyPlaceholder2"></div>
            {this.state.adminMenuData.length > 0 &&
            <div>
                <Button
                  aria-owns={openAdminMenu ? 'menu-appbar-admin' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleAdminMenu}
                  color="inherit" >
                  <SettingsIcon />
                  <Typography variant="h6" className="uniNav-header-text">
                    Admin
                  </Typography>
                </Button>
                <Menu
                  id="menu-appbar-admin"
                  anchorEl={anchorElAdmin}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={openAdminMenu}
                  onClose={this.handleClose}>
                  <AdminMenu handleClose={this.handleClose}></AdminMenu>
                </Menu>
              </div>
              }
              { this.state.whatsNewMenuData.length > 0 &&
                <div>
                    <Button
                    aria-owns={openWhatsNewMenu ? 'menu-appbar-whats-new' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleWhatsNewMenu}
                    color="inherit" >
                    <NotificationsActiveIcon />

                    <Typography variant="h6" className="uniNav-header-text">
                        What's New
                    </Typography>
                    </Button>
                    <Menu
                    id="menu-appbar-whats-new"
                    anchorEl={anchorElWhatsNew}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={openWhatsNewMenu}
                    onClose={this.handleClose}>
                    <WhatsNewMenu handleClose={this.handleClose}></WhatsNewMenu>
                    </Menu>
                </div>
                }
              { this.state.helpMenuData.length > 0 &&
                <div>
                  <Button
                    aria-owns={openHelpMenu ? 'menu-appbar-help' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleHelpMenu}
                    color="inherit" >
                    <Help />
                    <Typography variant="h6" className="uniNav-header-text">
                      Help
                    </Typography>
                  </Button>
                  <Menu
                    id="menu-appbar-help"
                    anchorEl={anchorElHelp}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={openHelpMenu}
                    onClose={this.handleClose}>
                    <HelpMenu handleClose={this.handleClose}></HelpMenu>
                  </Menu>
                </div>
              }
              { this.state.userMenuData.length > 0 &&
                <div>
                  <Button
                    aria-owns={openUserMenu ? 'menu-appbar-user' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleUserMenu}
                    color="inherit">
                    <AccountCircle />
                    <Typography variant="h6" className="uniNav-header-text">
                      {fullName}
                    </Typography>
                  </Button>
                  <Menu
                    id="menu-appbar-user"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={openUserMenu} onClose={this.handleClose}>
                    <UserMenu handleClose={this.handleClose}></UserMenu>
                  </Menu>
                </div>
                }
          </Toolbar>
      </div>
    );
  }
}

TopNav.propTypes = {
  classes: PropTypes.object.isRequired,
  adminActions: PropTypes.object.isRequired,
  adminMenuData: PropTypes.object.isRequired,
  helpActions: PropTypes.object.isRequired,
  helpMenuData: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
  userMenuData: PropTypes.object.isRequired,
  whatsNewMenuData: PropTypes.object.isRequired
};

function mapStateToProps(state){
  return {
    adminMenuData: state.adminMenuData,
    helpMenuData: state.helpMenuData,
    userMenuData: state.userMenuData,
    whatsNewMenuData: state.whatsNew
  }
}

function mapDispatchToProps(dispatch){
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    helpActions: bindActionCreators(helpActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    whatsNewActions: bindActionCreators(whatsNewActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopNav));
